<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

 <template>
	<div class="sign-in bg-gray-3">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="padding-top: 170px;">

			<a-col :span="24" :md="14" :lg="14" :xl="{span: 14, offset: 0}" class="col-form mt-20">
				
				<a-card :bordered="false" class="card-info px-5 mx-0 pt-20">
					<a-row :gutter="[24,24]">
						<a-button id="btnBordered" type="text" class="primary ml-15 mb-20" v-if="current > 0 && current != 2" @click="previousScreen"><a-icon type="arrow-left" theme="outlined" /> &nbsp;&nbsp; Back  </a-button>
						<a-col :span="24">
							<a-steps :current="current">
								<a-step v-for="item in steps" :key="item.title" :title="item.title" />
							</a-steps>
							<div class="steps-content">
								<!-- {{ steps[current].content }} -->
								<template>
									<a-row ty :gutter="[24,24]">
										
										<a-col :span="24" :md="24" :lg="24">

											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 50px;" v-if="current==0">
												<a-col :span="24" :md="11" :lg="11" :xl="{span: 11, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 16px;">
																<strong>Enter details below</strong>
															</label>
														</a-col>
													</a-row>

													<a-alert
														v-if="error.show"
														:message="error.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>

													<a-form
														id="components-form-demo-normal-login"
														:form="basicDetailsForm"
														class="login-form mt-20 pt-20"
														@submit="handleSubmitBasicDetails"
														:hideRequiredMark="true">

														<a-row :gutter="[24,24]">
															<a-col :span="24" :sm="24" :md="24" :lg="24">
																<a-form-item class="py-0 my-0" label="First Name" :colon="false">
																	<a-input
																		v-decorator="[
																			'firstName', 
																			{ rules: [{ required: true, message: 'Please enter your first name!' }] }, 
																		]" 
																		type="text" 
																		placeholder="First Name" />
																</a-form-item>
															</a-col>

															<a-col :span="24" :sm="24" :md="24" :lg="24">
																<a-form-item class="py-0 my-0" label="Last Name" :colon="false">
																	<a-input
																		v-decorator="[
																			'lastName', 
																			{ rules: [{ required: true, message: 'Please enter your last name!' }] },
																		]" 
																		type="text" 
																		placeholder="Last Name" />
																</a-form-item>
															</a-col>
														</a-row>

														<a-row :gutter="[24,24]">
															<a-col :span="24" :sm="24" :md="24" :lg="24">
																<a-form-item 
																	class="py-0 my-0"
																	label="Gender"
																	placeholder="Select Gender"
																	:colon="false">
																	<a-radio-group v-model="newUser.gender">
																		<a-radio :value="false">Male</a-radio>
																		<a-radio :value="true">Female</a-radio>
																	</a-radio-group>
																	<!-- <a-select placeholder="Select Gender" 
																		v-decorator="[ 'gender',
																			{ rules: [{ required: true, message: 'Please select your gender!' }] },]">
																		<a-select-option v-for="option in genders"
																			:value="option.uuid"
																			:key="option.name">
																			{{ option.name }}
																		</a-select-option>
																	</a-select> -->
																</a-form-item>
															</a-col>

															<!-- <a-col :span="24" :sm="12" :md="12" :lg="12">
																<a-form-item class="py-0 my-0" label="Date of Birth" :colon="false">
																	<a-date-picker
																	format="DD MMM YYYY"
																	style="width: 100%"
																	v-decorator="[
																		'dateOfBirth', 
																		{ rules: [] }
																	]"
																	placeholder="Birthday" />
																</a-form-item>
															</a-col> -->
														</a-row>
														
														<a-form-item class="mt-2 text-center mt-20 pt-5">
															<a-button :loading="isLoading" type="primary" style="width: 100px !important;" block html-type="submit" class="login-form-button mt-10 text-white" >
																NEXT
															</a-button>
														</a-form-item>
														
													</a-form>
												</a-col>
											</a-row>

											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 50px;" v-if="current==1">
												<a-col :span="24" :md="12" :lg="12" :xl="{span: 12, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 16px;">
																<strong>Enter details below</strong>
															</label>
														</a-col>
													</a-row>

													<a-alert
														class="mt-20"
														v-if="errorUsername.show"
														:message="errorUsername.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>

													<a-form
														:form="usernameForm"
														class="login-form mt-20"
														@submit="handleSubmitUsername"
														:hideRequiredMark="true">

														<a-input-group>
															<a-row :gutter="24">
																<a-col :span="5" class="w-100 pr-0 mr-0">
																	<a-form-item class="mb-10 w-100" label="Username" :colon="false">
																		<vue-country-code
																			style="height: 40px; width: 100%; margin-top: 4px;"
																			@onSelect="onSelectCountry"
																			:enableSearchField="true"
																			defaultCountry="tz">
																		</vue-country-code>
																	</a-form-item>
																</a-col>
																<a-col :span="19" class="ml-0 pl-1">
																	<a-form-item class="mb-10" :colon="false">
																		<a-input 
																			style="margin-top: 32px; width: 100%;"
																			v-decorator="[
																				'username',
																				{ rules: [{ required: true, message: 'Please input your phone number or email address!' }] },
																			]" 
																			placeholder="Email or Phone number" />
																	</a-form-item>
																</a-col>
																
															</a-row>
															
														</a-input-group>

														<a-form-item class="mb-15" label="Password" :colon="false">
															<a-input
																v-decorator="[
																	'password',
																	{ rules: [
																		{ required: true, message: 'Please input your password!' },
																		{ min: 8, message: 'Password should have atleast 8 characters!' }
																	] },
																]" 
																type="password" 
																placeholder="Password" />
														</a-form-item>

														<a-form-item class="mt-2 text-center mt-20 pt-5">
															<a-button :loading="newUser.usernameLoading" type="primary" style="width: 100px !important;" block html-type="submit" class="login-form-button mt-10 text-white" >
																Register
															</a-button>
														</a-form-item>
														
													</a-form>
												</a-col>
											</a-row>

											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 50px;" v-if="current==2">
												<a-col :span="24" :md="12" :lg="12" :xl="{span: 12, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 16px;">
																<strong>Verify token sent to {{ newUser.username }}</strong>
															</label>
														</a-col>
													</a-row>

													<a-alert
														class="mt-10"
														v-if="errorToken.show"
														:message="errorToken.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>

													<a-form
														id="components-form-demo-normal-login"
														:form="tokenForm"
														class="login-form mt-20 pt-20"
														@submit="handleSubmitPassword"
														:hideRequiredMark="true">

														<a-form-item class="mb-10" label="Token" :colon="false">
															<a-input 
																v-model="resetPassword.token"
																type="number"
																v-decorator="[ 'token',
																{ rules: [{ required: true, message: 'Please input verification code!' }] },
																]" 
																placeholder="Enter verification code" />
														</a-form-item>

														<a-row>
															<a-col :span="24" class="text-center pb-5">
																Didn't receive token? <a-button id="textBtn" class="text-primary px-0 mx-0 pl-2" type="text" @click="resendToken" style="text-decoration: none !important; font-size: 14px;">
																	Resend token
																</a-button>
															</a-col>
														</a-row>
														
														<a-form-item class="mt-2 text-center mt-20 pt-5">
															<a-button :loading="isLoadingSubmission" type="primary" style="width: 100px !important;" block html-type="submit" class="login-form-button mt-10 text-white" >
																Verify
															</a-button>
														</a-form-item>
														
													</a-form>
												</a-col>
											</a-row> 
														
										</a-col>
									</a-row>
								</template>
							</div>
							<div class="steps-action">
								<!-- <a-button v-if="current < steps.length - 1" type="primary" @click="next">Next</a-button>
								
								<a-button v-if="current > 0" style="margin-left: 8px" @click="prev">Previous</a-button> -->
							</div>
						</a-col>
					</a-row>

					<a-row :gutters="24" class="pt-10 pb-10">
						<a-col :span="24" class="text-center pb-5">
							Already have an account? <a class="text-primary" href="/sign-in" style="text-decoration: none !important;">
								<strong>Sign in</strong>
							</a>
						</a-col>
					</a-row>

					

					
				</a-card>
			</a-col>


		</a-row>
		
	</div>
</template>

<script>
	import VueCountryCode from "vue-country-code-select";
    import { notification } from 'ant-design-vue';

	export default ({
		components: {
			VueCountryCode
		},

		data() {
			return {
				// Binded model property for "Sign In Form" switch button for "Remember Me" .
				// form: null,
				// formPassword: null,

				

				genders: [
					{ uuid: false, name: "Male" },
					{ uuid: true, name: "Female" },
				],

				current: 0,
				steps: [
					{
						title: 'Basic Details',
						content: 'First-content',
					},
					{
						title: 'Account Details',
						content: 'Second-content',
					},
					// {
					// 	title: 'Password',
					// 	content: 'Third-content',
					// },
					{
						title: 'Verify Account',
						content: 'Third-content',
					},
				],

				academicEducationSystems: [],
				nonAcademicEducationSystems: [],

				newUser: {
					firstName: null,
					lastName: null,
					email: null,
					mobile: null,
					password: null,
					passwordConfirmation: null,
					gender: null,
					dateOfBirth: null,
					countryCode: null,
					latLon: null,
					role: null,

					username: null,
					countryPhoneCode: null,
					countryIsoCode: null,
					educationSystemUuid: null,
					academicEducationSystemUuid: null,
					nonAcademicEducationSystemUuid: null,


					usernameLoading: false,
				},
				
				error: {
					message: null,
					show: false,
				},

				errorUsername: {
					message: null,
					show: false,
				},

				errorToken: {
					message: null,
					show: false,
				},

				errorPassword: {
					message: null,
					show: false,
				},

				resetPassword: {
					token: null,
					password: null,
					passwordConfirmation: null,
				},

				learning: {
					uuid: null,
					password: null,
					passwordConfirmation: null,
				},

				username: null,

				

				isLoading: false,
				isLoadingToken: false,
				isLoadingPassword: false,
				isLoadingSubmission: false,

				
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.basicDetailsForm = this.$form.createForm(this, { name: 'basic_details' });
			this.usernameForm = this.$form.createForm(this, { name: 'username' });
			this.passwordForm = this.$form.createForm(this, { name: 'password_details' });
			this.tokenForm = this.$form.createForm(this, { name: 'token_details' });
		},
		created() {
			this.getUserLocationDetails();
			this.getStudentRole();
		},
		methods: {

			next() {
				this.current++;
			},

			previousScreen() {
				if(this.current > 0) {
					this.current--;
				}
			},



			async getUserLocationDetails() {

				let token = await localStorage.getItem("user_token")

                let url = `${this.$BACKEND_LEGACY_URL}/geo-location`;

                const config = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				};

                this.$AXIOS.get(url, config).then(async(response) => {

					this.newUser.countryPhoneCode = response.data.countryPhoneCode.substring(1)
					this.newUser.countryIsoCode = response.data.countryIsoCode.toLowerCase();
					this.newUser.countryCode = response.data.countryIsoCode.toLowerCase();
					this.newUser.latLon = [response.data.countryLat, response.data.countryLon];
					
                }).catch(async(error) => {

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


			async getStudentRole() {

                let url = `${this.$BACKEND_URL}/roles?code=teacher&category=silabu`;

                this.$AXIOS.get(url).then(async(response) => {

					if(response.data.data != null && response.data.data.length > 0) {
						this.newUser.role = response.data.data[0].uuid;
					}
					
					
                }).catch(async(error) => {

                });
            },
			

			onSubmitToken() {
				this.isLoadingToken = true;
				this.errorToken.message = null;
				this.errorToken.show = false;

				if(this.resetPassword.token != null && this.resetPassword.token.length == 6) {
					this.current++;
				}else{
					this.errorToken.message = "Invalid token";
					this.errorToken.show = true
				}
			},

			onSelectCountry({name, iso2, dialCode}) {
				// console.log(name)
				// console.log(iso2)
				this.newUser.country = name.toLowerCase();
				this.newUser.countryPhoneCode = dialCode;
			},

			handleClose() {
				this.error.show = false;
			},

			handleCloseToken() {
				this.errorToken.show = false;
			},

			handleClosePassword() {
				this.errorPassword.show = false;
			},


			handleSubmitBasicDetails(e) {
				e.preventDefault();
				this.basicDetailsForm.validateFields((err, values) => {
					if ( !err ) {

						let payload = values;

						this.newUser.firstName = values.firstName;
						this.newUser.lastName = values.lastName;
						// this.newUser.gender = values.gender;
						// this.newUser.dateOfBirth = this.moment(values.dateOfBirth._d).format("YYYY-MM-DD");

						this.current++;
					}
				});
			},



			handleSubmitUsername(e) {
				e.preventDefault();
				this.usernameForm.validateFields((err, values) => {
					if ( !err ) {

						this.errorUsername.show = false;
						this.errorUsername.message = null;

						this.newUser.usernameLoading = true;

						const constUsername = values.username;
						const password = values.password;
						let username = '';

						if(!constUsername.includes("@")){

							if(constUsername.substring(0, 4).includes(this.newUser.countryPhoneCode) && !constUsername.includes("+")) {
								username = "+" + constUsername;
							}

							else if(constUsername.substring(0, 4).includes(this.newUser.countryPhoneCode) && constUsername.includes("+")) {
								username =  constUsername;
							}

							else if(constUsername[0] == "0" || constUsername[0] == 0) {
								username = "+" + this.newUser.countryPhoneCode + "" + constUsername.substring(1)
							}

							else if(constUsername[0] != "0" || constUsername[0] != 0) {
								username = "+" + this.newUser.countryPhoneCode + "" + constUsername
							}

							else {
								username = constUsername;
							}

						}else{
							username = constUsername;
						}

						const config = {
							headers: {
								'Content-Type': 'multipart/form-data',
							},
						};

						// let url = `${this.$BACKEND_URL}/auths/check-user`;

						// const formData = new FormData(); 

						// formData.append("username", username); 

						this.newUser.username = username;
						this.newUser.password = password;

						this.submitForm();
						
						// this.$AXIOS.post(url, formData, config).then(async(response) => {
						// 	if (response.status >= 200 && response.status < 210) {
								
						// 		// if(response.data.status) {
						// 		// 	this.notify(`Verification token has been sent to your ${username.includes('@') ? 'email address' : 'phone number'}`, 'success')
						// 		// 	this.errorUsername.message = err.response != null  && err.response.data != null ? err.response.data.message : "Something went wrong, please try again!";
						// 		// 	this.errorUsername.show = true
						// 		// }else{

						// 			this.errorUsername.message = response.data.message;
						// 			this.errorUsername.show = true

									
						// 		// }

								

						// 	}

						// 	this.newUser.usernameLoading = false

						// }).catch((err) => {
						// 	console.log(err)
						// 	this.newUser.usernameLoading = false;
							
						// 	this.newUser.username = username;
						// 	this.newUser.emailAddress = username.includes("@") ? username : null;
						// 	this.newUser.phoneNumber = !username.includes("@") ? username : null;

						// 	this.current++;
						// });
					}
				});
			},


			handleSubmitPassword(e) {
				e.preventDefault();

				this.tokenForm.validateFields((err, values) => {
					if ( !err ) {

						this.errorToken.show = false;
						this.errorToken.message = null;

						this.isLoadingSubmission = true;

						console.log(values)
						const pin = values.token;
						const username = this.newUser.username;
						// const passwordConfirmation = values.passwordConfirmation;

						let url = `${this.$BACKEND_URL}/auths/verify-pin`;

						this.$AXIOS.post(url, { username, pin }).then(async(response) => {
							if (response.status >= 200 && response.status < 210) {

								this.notify(response.data.message, 'success')

								await localStorage.setItem("user_token", response.data.data.token)

								this.$router.push("/onboarding-tutor")
								
							}
							this.isLoadingSubmission = false;
						}).catch((err) => {
							console.log(err)
							this.isLoadingSubmission = false;
							
							this.errorToken.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
							this.errorToken.show = true
						});

					}
				});
			},



			submitForm() {

				this.newUser.usernameLoading = true
					
				let url = `${this.$BACKEND_URL}/users`;
				
				this.$AXIOS.post(url, this.newUser).then(async(response) => {
					if (response.status >= 200 && response.status < 210) {

						this.resendToken();

						this.current++;
						
					}
					this.newUser.usernameLoading = false;
				}).catch((err) => {
					console.log(err)
					this.newUser.usernameLoading = false;
					
					this.errorUsername.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
					this.errorUsername.show = true
				});
					
			},


			resendToken() {
				// this.isLoadingToken = true
					
				let url = `${this.$BACKEND_URL}/auths/resend-pin`;

				let username = this.newUser.username
				
				this.$AXIOS.post(url, { username }).then(async(response) => {
					if (response.status >= 200 && response.status < 210) {

						this.notify(`Verification token has been sent to ${username}`, 'success')
						
					}
					this.isLoadingToken = false;
				}).catch((err) => {
					console.log(err)
					// this.isLoadingToken = false;
					
					this.errorToken.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
					this.errorToken.show = true
				});
			},

			notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
                
            },
		},
	})

</script>

<style lang="scss" scoped>
	body {
		background-color: #ffffff;
	}
	.ant-steps-item-process .ant-steps-item-icon { background: red; }
	#signinBtn, #signinBtn:hover {
		background-color: #632163 !important;
		color: white !important;
	}
	#submitUsernameBtn, #submitUsernameBtn:hover, #submitTokenBtn, #submitTokenBtn:hover {
		background-color: #632163 !important;
		color: white !important;
	}
	#backBtn, #backBtn:hover {
		color: #632163 !important;
		border-color: #632163 !important;
		background-color: white !important;
	}

	.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: red !important;
}

.custome-step .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: red !important;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
//    background-color: #632163 !important;
   color: #ffffff;
}

.ant-steps-item-process .ant-steps-item-icon {
    border-color: #632163 !important;
    background: #632163 !important;
}

#divId {
	display: inline-block !important;
}
</style>
